import { useQuery } from '@apollo/client';
import { CloudSync } from '@mui/icons-material';
import { Navigate, useParams } from 'react-router-dom';
import { object, string } from 'yup';
import { useUpdateDataSource } from '~/api/data-sources';
import { InlineEditTitle, Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { DataSourceToolbarDocument } from '../queries/DataSourceToolbar.generated';

export const AuditLogsDataSourceToolbar = () => {
  const [update] = useUpdateDataSource();
  const { currentNetwork } = useAppContext();
  const link = useLink();
  const params = useParams<{ dataSourceId: string }>();
  const dataSourceId = parseInt(params.dataSourceId ?? '');

  const { data, loading } = useQuery(DataSourceToolbarDocument, {
    variables: { networkId: currentNetwork.id, dataSourceId },
  });

  const dataSource = data?.network?.dataSource;

  if (loading || !dataSource) return !loading ? <Navigate to={link('/not-found')} replace /> : null;

  return (
    <Toolbar
      titleIcon={<CloudSync />}
      titleEditor={
        <InlineEditTitle
          inputId={`data-sources-${dataSource.id}`}
          value={dataSource.name}
          update={(value: string) =>
            update({ variables: { id: dataSource.id, name: value.trim() } })
          }
          tooltip="Edit the name of this data source"
          validationSchema={object({
            name: string().required('Data source name is required').trim(),
          })}
        />
      }
    />
  );
};

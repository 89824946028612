import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { UpdateFontDocument } from './update.generated';

export const useUpdateFont = (options: ApiOptions<typeof UpdateFontDocument> = {}) =>
  useMutation(UpdateFontDocument, {
    ...useNotifications(UpdateFontDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Font updated',
    }),
    ...options,
  });

import {
  DataGridPro as MuiDataGrid,
  type DataGridProProps,
  type GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { PER_PAGE } from '~/lib/pagination';

export * from './pagination';

export const ROW_HEIGHT = 45;

export const ROW_SPACING = 0;

export const ROW_TOTAL_HEIGHT = ROW_HEIGHT + ROW_SPACING;

export const SeparatedDataGrid = <T extends GridValidRowModel>(props: DataGridProProps<T>) => (
  <MuiDataGrid<T>
    sx={{
      '--DataGrid-overlayHeight': '225px',
      '--DataGrid-rowBorderColor': '#e6e6e6',
      border: 0,
      fontSize: '14px',
      '& .MuiDataGrid-columnHeader': {
        color: '#969696',
        fontSize: '0.7rem',
        fontWeight: 600,
        textTransform: 'uppercase',
        '& .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-columnSeparator--resizable': {
          color: 'rgba(0,0,0,0.25)',
          display: 'flex',
          '&:hover': {
            color: 'rgba(0,0,0,0.25)',
          },
        },
      },
      '& .MuiDataGrid-actionsCell': {
        gridGap: 0,
      },
      '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
        outline: 'none',
      },
      '& .MuiDataGrid-row': {
        borderRadius: '0',
        boxShadow: 'none',
        background: 'white',
        borderBottom: '1px solid #e8e8e8',
        '&:hover': {
          background: '#F9FAFA',
          boxShadow: 'none',
          cursor: 'pointer',
        },
        '&.Mui-selected': {
          background: 'white',
          boxShadow: 'inset 0 0 0 2px #008cc8, rgba(0, 0, 0, 0.1) 0px 1px 2px 0px',
          '&:hover': {
            background: 'white',
            boxShadow: 'inset 0 0 0 2px #008cc8, rgba(0, 0, 0, 0.1) 0px 4px 8px 0px',
          },
        },
        '& .MuiDataGrid-cellCheckbox': {
          '& .Mui-disabled': {
            // "hide" disabled checkboxes
            visibility: 'hidden',
          },
        },
      },
      '& .MuiDataGrid-scrollbar': {
        display: 'none',
      },
      '& .MuiDataGrid-cell': {
        border: 0,
      },
      '& .readonly': {
        '&:hover': {
          cursor: 'default',
        },
      },
    }}
    autoHeight
    checkboxSelection={false}
    disableColumnMenu
    disableColumnReorder
    hideFooterSelectedRowCount
    initialState={{
      pagination: {
        paginationModel: { pageSize: PER_PAGE, page: 0 },
      },
    }}
    localeText={{
      MuiTablePagination: {
        labelRowsPerPage: 'Per page',
      },
    }}
    getRowHeight={() => ROW_HEIGHT}
    getRowSpacing={() => ({ top: ROW_SPACING })}
    pageSizeOptions={[25, 50, 100, 250, 500]}
    pagination
    slotProps={{
      loadingOverlay: {
        variant: 'skeleton',
      },
    }}
    {...props}
  />
);

export const ReadOnlyDataGrid = <T extends GridValidRowModel>(props: DataGridProProps<T>) => (
  <SeparatedDataGrid<T> getRowClassName={() => 'readonly'} {...props} />
);

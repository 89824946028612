import { useQuery } from '@apollo/client';
import { AddCircle, Delete, Edit, FontDownload } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useDeleteFont } from '~/api/fonts';
import { RouterBreadcrumbs } from '~/components/RouterBreadcrumbs';
import { Count } from '~/components/count';
import { ReadOnlyDataGrid } from '~/components/data-grid';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { EmptyState } from '~/components/empty-state';
import { SearchBar } from '~/components/forms/search-bar';
import { Link } from '~/components/link';
import { PageContainer } from '~/components/page-layout';
import { SearchContainer } from '~/components/search';
import { TableAction } from '~/components/table';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { useConfirmDialog } from '~/hooks/dialogs';
import EmptyIllustration from '~/images/illustrations/upload-media.svg';
import { FontListDocument, type FontList__Font } from './Fonts.generated';
import { NewFontDialog, UpdateFontDialog } from './components';

export const Fonts = () => {
  const { currentNetwork } = useAppContext();

  const [searchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';

  const { data, loading, error } = useQuery(FontListDocument, {
    variables: { networkId: currentNetwork.id, search },
  });

  const [fontsLoaded, setFontsLoaded] = useState(false);

  const [fontToUpdate, setFontToUpdate] = useState<FontList__Font | null>(null);

  const [fontToDelete, setFontToDelete] = useState<FontList__Font | null>(null);
  const [deleteFont] = useDeleteFont();
  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  const [newFontOpen, setNewFontOpen] = useState(false);

  const fonts = useMemo(() => data?.network?.fonts ?? [], [data]);

  useEffect(() => {
    if (loading || fontsLoaded) return;

    void Promise.all(
      fonts.map((x) => {
        const fontFace = new FontFace(x.name, `url(${x.file.uri})`);
        document.fonts.add(fontFace);
        return fontFace.load();
      }),
    ).then(() => setFontsLoaded(true));
  }, [fonts, fontsLoaded, loading]);

  if ((!loading && !data) || error) return <Navigate replace to="/not-found" />;

  return (
    <>
      <Toolbar
        actions={
          <Button
            color="primary"
            onClick={() => setNewFontOpen(true)}
            startIcon={<AddCircle />}
            variant="contained"
          >
            New Font
          </Button>
        }
        breadcrumbsLabel={<RouterBreadcrumbs />}
        titleIcon={<FontDownload />}
        titleText="Fonts"
      />
      <PageContainer>
        <SearchContainer>
          <SearchBar placeholder="Search Fonts" search={search} />
          <Count selectedCount={0} totalCount={fonts.length} thing="font" />
        </SearchContainer>
        <ReadOnlyDataGrid
          columns={[
            {
              field: 'name',
              flex: 1,
              renderCell: ({ row }) => <Box sx={{ fontFamily: row.name }}>{row.name}</Box>,
              sortable: false,
            },
            {
              field: 'fileUri',
              flex: 1,
              headerName: 'File',
              renderCell: ({ row }) => (
                <Link target="_blank" to={row.file.uri} sx={{ '&:hover': { cursor: 'pointer' } }}>
                  Download
                </Link>
              ),
              sortable: false,
            },
            {
              align: 'right',
              field: 'actions',
              flex: 1,
              getActions: ({ row }) => [
                <TableAction
                  key={`update-${row.id}`}
                  title="Update Font"
                  Icon={Edit}
                  onClick={() => setFontToUpdate(row)}
                />,
                <TableAction
                  color="error"
                  disabled={!row.canDelete.value}
                  key={`delete-${row.id}`}
                  title={row.canDelete.reasons?.fullMessages.join() || 'Delete Font'}
                  Icon={Delete}
                  onClick={async () => {
                    setFontToDelete(row);
                    if (!(await confirmDelete())) return;
                    void deleteFont({ variables: { id: row.id, search } });
                  }}
                />,
              ],
              headerAlign: 'right',
              headerName: '',
              type: 'actions',
            },
          ]}
          disableRowSelectionOnClick
          loading={loading || !fontsLoaded}
          rows={fonts}
          slots={{
            columnResizeIcon: () => null,
            noRowsOverlay: () => (
              <EmptyState
                illustration={EmptyIllustration}
                description="Fonts can be used to style content."
                header="Upload a Font"
              />
            ),
          }}
        />
      </PageContainer>
      {newFontOpen && (
        <NewFontDialog
          fullWidth
          onAdd={() => {
            setNewFontOpen(false);
            setFontsLoaded(false);
          }}
          onClose={() => setNewFontOpen(false)}
          open
        />
      )}
      {fontToUpdate && (
        <UpdateFontDialog
          font={fontToUpdate}
          fullWidth
          onClose={() => setFontToUpdate(null)}
          onUpdate={() => {
            setFontToUpdate(null);
            setFontsLoaded(false);
          }}
          open
        />
      )}
      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt={
          <span>
            Deleting the "{fontToDelete?.name}" font will remove it from all content where it is
            used. <strong>This operation may not be undone.</strong>
          </span>
        }
        title="Delete Font"
      />
    </>
  );
};

import { SkipNext } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, Tooltip, Typography, styled } from '@mui/material';
import { memo, useEffect, useRef, useState, type ReactNode } from 'react';
import type { PreviewControl } from '~/hooks/preview';

interface ContainerProps {
  aspectRatio?: string | number;
  children: ReactNode;
  disableMinHeight?: boolean;
}

export const Container = ({ aspectRatio = '16/9', children, disableMinHeight }: ContainerProps) => {
  return (
    <Box
      sx={{
        borderColor: 'common.black',
        borderRadius: (theme) => theme.spacing(1),
        borderStyle: 'solid',
        borderWidth: (theme) => theme.spacing(1),
        boxShadow: '0 8px 8px 0 rgba(0, 0, 0, 50%)',
        maxWidth: `calc((100vh - 25rem) * calc(${aspectRatio}))`,
        margin: '0 auto',
        overflow: 'hidden',
        position: 'relative',
        '&::before': {
          content: '""',
          display: 'block',
          paddingBottom: `calc(100% / calc(${aspectRatio}))`,
        },
        minHeight: (theme) => (disableMinHeight ? 'inherit' : theme.spacing(5)),
        minWidth: disableMinHeight ? '100%' : 'auto',
        '&:hover .control-bar': {
          opacity: 1,
        },
      }}
    >
      {children}
    </Box>
  );
};

const Iframe = styled('iframe')({
  backgroundColor: '#000',
  border: 0,
  height: '100%',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
});

const ControlBar = ({ previewControl }: { previewControl: PreviewControl | undefined }) => {
  return (
    <Box
      className="control-bar"
      display="flex"
      justifyContent="center"
      width="100%"
      height="40px"
      sx={{
        position: 'absolute',
        bottom: '8px',
        opacity: 0,
        transition: 'opacity 0.3s ease',
      }}
    >
      {previewControl && (
        <Box
          width="95%"
          sx={{
            backgroundColor: 'rgba(0, 0, 0, .5)',
            borderRadius: 2,
            margin: '0',
          }}
        >
          <Tooltip title="Advance">
            <IconButton sx={{ color: 'common.white' }}>
              <SkipNext onClick={() => previewControl.advance()} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

const Preloader = ({ show }: { show: boolean }) => (
  <Box
    sx={{
      alignItems: 'center',
      backgroundColor: 'common.black',
      border: 0,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
      opacity: show ? 1 : 0,
      transition: 'opacity 1s ease',
    }}
  >
    <CircularProgress
      sx={{
        color: 'common.white',
      }}
      size={60}
      thickness={2}
    />
    <Typography sx={{ color: 'common.white', mt: 2 }} variant="h4">
      Loading preview...
    </Typography>
  </Box>
);

export interface PreviewProps {
  /** This can be a number or a CSS-formatted ratio string, e.g. `16/9` */
  aspectRatio?: string | number;
  /** Payload the describes what to preview. THis is dependent on the preview type. */
  payload: unknown;
  /** Set this to the proper preview URL */
  src: string;
  previewControl?: PreviewControl | undefined;
  disableMinHeight?: boolean;
  displayControlBar?: boolean;
}

export const Preview = memo(function Preview({
  aspectRatio,
  disableMinHeight = false,
  displayControlBar = false,
  payload,
  previewControl,
  src,
}: PreviewProps) {
  const ref = useRef<HTMLIFrameElement>(null);

  const [showPreloader, setShowPreloader] = useState(true);

  const [iframeLoaded, setIframeLoaded] = useState(false);

  const json = payload ? JSON.stringify(payload) : null;

  useEffect(() => {
    if (json == null && iframeLoaded) setIframeLoaded(false);
    if (json != null && iframeLoaded) ref.current?.contentWindow?.postMessage(json, '*');
  }, [iframeLoaded, json]);

  useEffect(() => {
    const onAdvance = () =>
      ref.current?.contentWindow?.postMessage(JSON.stringify({ type: 'playerAdvance' }), '*');
    previewControl?.eventTarget.addEventListener('advance', onAdvance);
    return () => previewControl?.eventTarget.removeEventListener('advance', onAdvance);
  }, [previewControl?.eventTarget]);

  useEffect(() => {
    const preloaderTimeout = setTimeout(() => {
      setShowPreloader(false);
    }, 1500);

    return () => clearTimeout(preloaderTimeout);
  }, []);

  return (
    <Container aspectRatio={aspectRatio} disableMinHeight={disableMinHeight}>
      <Iframe ref={ref} src={src} onLoad={() => setIframeLoaded(true)} id="preview" />
      <Preloader show={showPreloader} />
      {displayControlBar && <ControlBar previewControl={previewControl} />}
    </Container>
  );
});

import { Close } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Dialog as MuiDialog,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
  type DialogProps,
  type DialogTitleProps as MuiDialogTitleProps,
} from '@mui/material';
import { type ReactNode } from 'react';
import { useAppContext } from '~/contexts/app';

export const Dialog = (props: DialogProps) => {
  const { impersonating } = useAppContext();
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <MuiDialog
      fullScreen={fullscreen}
      {...props}
      sx={{ top: impersonating ? '50px' : '0' }}
    ></MuiDialog>
  );
};

export interface DialogTitleProps extends Omit<MuiDialogTitleProps, 'component' | 'typography'> {
  onClose: (...args: never[]) => void;
}

export const DialogTitle = ({ children, onClose, ...props }: DialogTitleProps) => {
  return (
    <Box
      sx={{ px: 2, py: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      {...props}
    >
      <Typography variant="h3">{children}</Typography>
      <IconButton onClick={onClose} size="large">
        <Close fontSize="small" />
      </IconButton>
    </Box>
  );
};

export const DialogContentTitle = ({ children }: { children: ReactNode }) => {
  return (
    <Typography sx={{ mb: 2 }} variant="subtitle1">
      {children}
    </Typography>
  );
};

export const DialogForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),

  '& label': {
    alignItems: 'center',
    display: 'flex',
    fontSize: 14,

    '.label': {
      flexBasis: '25%',
      flexShrink: 0,
    },

    '.required::after': {
      content: '" *"',
      color: theme.palette.error.dark,
    },

    '& img': {
      maxHeight: '8rem',
      maxWidth: '8rem',
      background: `#eee url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill-opacity=".15"><rect x="5" width="5" height="5" /><rect y="5" width="5" height="5" /></svg>');`,
      backgroundSize: '10px 10px',
      boxShadow: '0 0 0 1px rgba(63,63,68,0.15), 0 1px 3px 0 rgba(63,63,68,0.25)',
    },
  },
}));

import { Delete, Edit, RemoveCircle } from '@mui/icons-material';
import { Box, Chip, useMediaQuery, useTheme } from '@mui/material';
import { type GridColDef, type GridRenderCellParams } from '@mui/x-data-grid-pro';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { ActionButton } from '~/components/button';
import { AuthorizedLink } from '~/components/links';
import { getScheduleDescription, isExpired } from '~/lib/schedules';
import { type DeviceSettings__ScheduledShow as ScheduledShow } from '../../../../queries/queries.generated';
import { useDevice } from '../../../context';

export interface UseColumnsProps {
  onDelete: (scheduledShow: ScheduledShow) => void;
  onRemoveDevice: (scheduledShow: ScheduledShow) => void;
  onUpdate: (scheduledShow: ScheduledShow) => void;
}

export const useColumns = ({
  onDelete,
  onRemoveDevice,
  onUpdate,
}: UseColumnsProps): GridColDef[] => {
  const { device } = useDevice();
  const theme = useTheme();
  const isSmallAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  return useMemo(
    () => [
      {
        field: 'name',
        flex: 0.15,
        headerName: 'Name',
        renderCell: ({ row }: GridRenderCellParams<ScheduledShow>) => row.name,
      },
      {
        field: 'label',
        flex: 0.125,
        headerName: '',
        sortable: false,
        renderCell: ({ row }: GridRenderCellParams<ScheduledShow>) => {
          return (
            <Box sx={{ display: 'flex', gap: 1 }}>
              {row.schedule?.allDay && <Chip color="primary" label="All Day" />}
              {isExpired(row.schedule?.rrule) && <Chip color="error" label="Expired" />}
            </Box>
          );
        },
      },
      {
        field: 'show',
        flex: 0.15,
        headerName: 'Show',
        renderCell: ({ row }: GridRenderCellParams<ScheduledShow>) => {
          return (
            <AuthorizedLink
              authorized={row.show.canUpdate.value && !row.show.originId}
              entity={row.show}
            />
          );
        },
        sortable: false,
      },
      {
        field: 'start',
        headerName: 'Start',
        minWidth: 150,
        renderCell: ({ row }: GridRenderCellParams<ScheduledShow>) => {
          return row.schedule?.startDate
            ? DateTime.fromISO(row.schedule.startDate, { zone: 'UTC' }).toFormat(
                'EEE, LLL dd, yyyy',
              )
            : '';
        },
        sortable: false,
      },
      {
        field: 'schedule',
        headerName: 'Schedule',
        sortable: false,
        flex: 0.25,
        renderCell: ({
          value: schedule,
        }: GridRenderCellParams<ScheduledShow, ScheduledShow['schedule']>) => {
          return schedule ? getScheduleDescription(schedule) : '';
        },
      },
      {
        field: 'devices',
        headerName: 'Devices',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: ({ row }: GridRenderCellParams<ScheduledShow>) => {
          return <Chip color="primary" label={row.deviceCount || 0} />;
        },
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: isSmallAndUp ? 150 : 0,
        getActions: ({ row }: GridRenderCellParams<ScheduledShow>) => {
          if (!row.canUpdate.value) return [];

          const actions = [];

          actions.push(
            <ActionButton
              key={`schedule-edit-${row.id}`}
              title="Edit Schedule"
              Icon={Edit}
              onClick={() => onUpdate(row)}
            />,
          );

          actions.push(
            row.devices.length === 1 && row.devices[0].id === device.id ? (
              <ActionButton
                color="error"
                title={'Delete Schedule'}
                Icon={Delete}
                onClick={() => onDelete(row)}
              />
            ) : (
              <ActionButton
                color="error"
                title={'Remove Device from Schedule'}
                Icon={RemoveCircle}
                onClick={() => onRemoveDevice(row)}
              />
            ),
          );
          return actions;
        },
      },
    ],
    [device, isSmallAndUp, onDelete, onRemoveDevice, onUpdate],
  );
};

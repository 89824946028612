import { Schedule } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { type ReactNode } from 'react';
import { object, string } from 'yup';
import { useUpdateScheduledShow } from '~/api/scheduled-show';
import { InlineEditTitle, Toolbar } from '~/components/toolbar';
import { type ScheduledShows__ScheduledShow as ScheduledShow } from '../../queries/queries.generated';

export interface ScheduleShowToolbarProps {
  actions?: ReactNode;
  scheduledShow: ScheduledShow;
}

export const ScheduleShowToolbar = ({ actions, scheduledShow }: ScheduleShowToolbarProps) => {
  const [updateScheduledShow] = useUpdateScheduledShow();

  return (
    <Toolbar
      actions={actions}
      titleIcon={<Schedule />}
      titleEditor={
        scheduledShow.canUpdate.value ? (
          <InlineEditTitle
            inputId={`scheduled-show-item-${scheduledShow.id}-name`}
            value={scheduledShow.name}
            update={(value) =>
              updateScheduledShow({
                variables: { id: scheduledShow.id, patch: { name: value.trim() } },
              })
            }
            tooltip="Edit the name of this Scheduled Show"
            validationSchema={object({
              name: string().optional().trim(),
            })}
          />
        ) : (
          <Typography variant="h3" noWrap>
            {scheduledShow.name}
          </Typography>
        )
      }
    />
  );
};

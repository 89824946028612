import type { FetchResult } from '@apollo/client';
import { Apps, Clear, MoreHoriz, Save, ScreenshotMonitor } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Tooltip, styled, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { object, string } from 'yup';
import { IconButton } from '~/components/button';
import { InlineEditTitle, Toolbar } from '~/components/toolbar';
import { useMoreActions } from '~/hooks/table';
import type { AppShow__ContentItem } from '../gql';
import { AppFormMenu } from './';

const AppImage = styled('img')(({ theme }) => ({
  width: '35px',
  objectFit: 'cover',
  height: '35px',
  borderRadius: theme.spacing(1),
}));

const AppCard = styled('div')(({ theme }) => ({
  width: '35px',
  height: '35px',
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.primary.dark,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.common.white,
}));

export interface AppFormToolbarProps {
  appName: string;
  displayActions: boolean;
  existingContentItem: AppShow__ContentItem | null | undefined;
  id: number;
  onCapture?: () => Promise<void>;
  onClose: () => void;
  onSave: () => void;
  onUpdateName: (value: string) => Promise<FetchResult | undefined> | void;
  saveDisabled: boolean;
}

export const AppFormToolbar = ({
  appName,
  displayActions,
  existingContentItem,
  id,
  onClose,
  onCapture,
  onSave,
  onUpdateName,
  saveDisabled,
}: AppFormToolbarProps) => {
  const [screenshotting, setScreenshotting] = useState(false);
  const [moreMenuProps, moreActionProps, moreTableActionProps] =
    useMoreActions<AppShow__ContentItem>();

  const theme = useTheme();
  const isSmallAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <Toolbar
        actions={
          <Box sx={{ display: 'flex', gap: 3 }}>
            <LoadingButton
              loadingPosition="start"
              startIcon={isSmallAndUp ? <Save /> : null}
              color="primary"
              variant="contained"
              size="large"
              disabled={saveDisabled}
              onClick={onSave}
            >
              {isSmallAndUp ? 'Save' : <Save />}
            </LoadingButton>
            {onCapture && 'chrome' in window && (
              <Tooltip arrow title="Generate thumbnail from the preview for this app">
                <LoadingButton
                  loading={screenshotting}
                  loadingPosition="start"
                  startIcon={isSmallAndUp ? <ScreenshotMonitor /> : null}
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={async () => {
                    setScreenshotting(() => true);
                    await onCapture();
                    setScreenshotting(() => false);
                  }}
                >
                  {isSmallAndUp ? 'Thumbnail' : <ScreenshotMonitor />}
                </LoadingButton>
              </Tooltip>
            )}
            {displayActions && (
              <Tooltip arrow title="More Actions" aria-label="more actions">
                <IconButton
                  onClick={
                    existingContentItem
                      ? moreTableActionProps(existingContentItem).onClick
                      : undefined
                  }
                  size="large"
                  variant="outlined"
                >
                  <MoreHoriz />
                </IconButton>
              </Tooltip>
            )}

            <Divider orientation="vertical" flexItem />

            <Tooltip arrow title="Close" aria-label="close">
              <IconButton size="large" variant="outlined" onClick={onClose}>
                <Clear />
              </IconButton>
            </Tooltip>
          </Box>
        }
        titleIcon={
          existingContentItem?.thumbnailUri ? (
            <AppImage src={existingContentItem.thumbnailUri} alt={existingContentItem.name} />
          ) : (
            <AppCard>
              <Apps />
            </AppCard>
          )
        }
        titleEditor={
          <InlineEditTitle
            inputId="app-name"
            value={appName}
            update={onUpdateName}
            tooltip="Edit the name of this App"
            validationSchema={object({
              name: string().required('App name is required').trim(),
            })}
          />
        }
      />

      <AppFormMenu
        canUpgrade={existingContentItem?.canUpgrade.value ?? false}
        id={id}
        moreActionProps={moreActionProps}
        moreMenuProps={moreMenuProps}
      />
    </>
  );
};

import { useQuery } from '@apollo/client';
import { MovieCreation } from '@mui/icons-material';
import { type ReactNode } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { object, string } from 'yup';
import { useRenameShow } from '~/api/shows';
import { InlineEditTitle, Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { ShowToolbarDocument } from './ShowToolbar.generated';
import { Actions } from './actions';

export interface ShowToolbarProps {
  actions?: ReactNode;
}

// TODO: Remove actions prop when advanced show edit dies!
export const ShowToolbar = ({ actions }: ShowToolbarProps) => {
  const [rename] = useRenameShow();
  const { currentNetwork } = useAppContext();
  const link = useLink();
  const params = useParams<{ showId: string }>();
  const showId = parseInt(params.showId ?? '');

  const { data, loading } = useQuery(ShowToolbarDocument, {
    variables: { networkId: currentNetwork.id, showId },
  });

  const show = data?.network?.show;

  if (loading || !show) return !loading ? <Navigate to={link('/not-found')} replace /> : null;

  return (
    <Toolbar
      actions={actions ?? <Actions show={show} />}
      titleIcon={<MovieCreation />}
      titleEditor={
        <InlineEditTitle
          inputId={`shows-item-${show.id}-name`}
          value={show.name}
          update={(value) => rename({ variables: { showId: show.id, name: value.trim() } })}
          tooltip="Edit the name of this show"
          validationSchema={object({
            name: string().required('Show name is required').trim(),
          })}
        />
      }
    />
  );
};

import { OndemandVideo } from '@mui/icons-material';
import { type ReactNode } from 'react';
import { object, string } from 'yup';
import { useRenamePlaylist } from '~/api/playlists';
import { InlineEditTitle, Toolbar } from '~/components/toolbar';
import { usePlaylist } from '../context';

export interface PlaylistToolbarProps {
  actions?: ReactNode;
}

export const PlaylistToolbar = ({ actions }: PlaylistToolbarProps) => {
  const { playlist } = usePlaylist();
  const [renamePlaylist] = useRenamePlaylist();

  return (
    <Toolbar
      actions={actions}
      titleIcon={<OndemandVideo />}
      titleEditor={
        <InlineEditTitle
          inputId={`playlists-item-${playlist.id}-name`}
          value={playlist.name}
          update={(value) =>
            renamePlaylist({
              variables: { playlistId: playlist.id, name: value.trim() },
            })
          }
          tooltip="Edit the name of this Playlist"
          validationSchema={object({
            name: string().required('Playlist name is required').trim(),
          })}
        />
      }
    />
  );
};

import { useQuery } from '@apollo/client';
import { InstallDesktop } from '@mui/icons-material';
import { Box, Button, DialogActions, DialogContent, MenuItem, Select } from '@mui/material';
import { useState } from 'react';
import { Dialog, DialogTitle } from '~/components/dialogs/components';
import { LoadingPane } from '~/components/loading-pane';
import { DeviceCommandKind } from '~/generated/graphql';
import type { Command } from '../../show/lib';
import { SamsungFirmwareDialogDocument } from './SamsungFirmwareDialog.generated';

export const isCompatibleVersion = (currentVersion: string | undefined, version: string) => {
  if (!currentVersion) return false;
  if (currentVersion === version) return false;

  return Number(version.split('-')[2]) > Number(currentVersion.split('-')[2]);
};

interface SamsungFirmwareDialogProps {
  deviceId: number;
  handleClick: (command: Command) => Promise<void>;
  onClose: () => void;
}

export const SamsungFirmwareDialog = ({
  deviceId,
  handleClick,
  onClose,
}: SamsungFirmwareDialogProps) => {
  const { data, loading } = useQuery(SamsungFirmwareDialogDocument, {
    variables: { deviceId },
  });
  const [version, setVersion] = useState('');

  const onSubmit = async () => {
    onClose();
    await handleClick({
      command: DeviceCommandKind.SamsungFirmware,
      description:
        'Installing firmware will cause the device to reboot when it is finished. Progress is reported on the device dashboard.  This process usually takes 10 - 30 minutes and depends on network download speed. Do not power off the device until the firmware upgrade has completed.',
      Icon: InstallDesktop,
      title: '',
      payload: { version },
    });
  };

  const currentVersion =
    data?.device?.metadata &&
    'firmware' in data.device.metadata &&
    typeof data.device.metadata.firmware === 'string'
      ? data.device.metadata.firmware
      : undefined;

  const noneCompatible = !data?.device?.samsungFirmwares.some((firmwareVersion) =>
    isCompatibleVersion(currentVersion, firmwareVersion.version),
  );

  return (
    <Dialog fullWidth maxWidth="xs" onClose={onClose} open>
      <DialogTitle onClose={onClose}>Install Firmware</DialogTitle>
      <DialogContent sx={{ fontSize: '1rem' }}>
        <LoadingPane in={loading && !data}>
          {data?.device && (
            <>
              <Box sx={{ mb: 2 }}>
                The device's current firmware version is <b>{currentVersion}</b>.
              </Box>
              {noneCompatible ? (
                <Box>There are no compatible versions available to install on this device.</Box>
              ) : (
                <Select<string>
                  displayEmpty
                  onChange={(event) => setVersion(event.target.value)}
                  sx={{ fontSize: 'inherit' }}
                  value={version}
                >
                  <MenuItem disabled value="">
                    Select a new version
                  </MenuItem>
                  {data.device.samsungFirmwares.map((firmwareVersion) => (
                    <MenuItem
                      disabled={!isCompatibleVersion(currentVersion, firmwareVersion.version)}
                      key={firmwareVersion.version}
                      value={firmwareVersion.version}
                    >
                      {firmwareVersion.version}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </>
          )}
        </LoadingPane>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose} variant="outlined">
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={loading || noneCompatible || version === ''}
          onClick={onSubmit}
          startIcon={<InstallDesktop />}
          variant="contained"
        >
          Install Firmware
        </Button>
      </DialogActions>
    </Dialog>
  );
};

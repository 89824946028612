import { Delete, Edit, Info } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import type {
  DataGridProProps as DataGridProps,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { useMemo, useState } from 'react';
import { useDeleteEvent } from '~/api/events/delete';
import { ActionButton } from '~/components/button';
import { SeparatedDataGrid } from '~/components/data-grid';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { EmptyState } from '~/components/empty-state';
import { ListCheckbox } from '~/components/list-row';
import { useConfirmDialog } from '~/hooks/dialogs';
import CreateEventTypeIllustration from '~/images/illustrations/create-event-type.svg';
import { formatDate } from '~/lib/datetime';
import type {
  NetworkEventTypeEvents__Event as Event,
  NetworkEventTypeEvents__EventType as EventType,
} from '../../queries/events.generated';
import { EditEventDialog } from './edit-event-dialog';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={CreateEventTypeIllustration}
    description="No Events exist for this Event Type."
    header="Add Events to this Event Type"
  />
);

interface RemoveButtonProps {
  eventType: EventType;
  event: Event;
}

const RemoveButton = ({ eventType, event }: RemoveButtonProps) => {
  const [deleteEvent, { called, reset }] = useDeleteEvent();

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  const onClick = async () => {
    if (!(await confirmDelete())) return;
    await deleteEvent({
      variables: { ids: event.id },
    });
    reset();
  };

  return (
    <>
      <ActionButton
        color="error"
        disabled={called}
        Icon={Delete}
        onClick={onClick}
        title="Delete"
      />

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Delete"
        deleteConfirm
        prompt={
          <span>
            Are you sure you want to delete <b>{event.name}</b> from <b>{eventType.name}</b>?
          </span>
        }
        title="Delete Event"
      />
    </>
  );
};

const useColumns = (editEvent: (event: Event) => void, eventType: EventType): GridColDef<Event>[] =>
  useMemo(
    () => [
      {
        field: 'name',
        flex: 0.5,
        renderCell: ({ row }: GridRenderCellParams<Event>) => (
          <Tooltip arrow title={row.fcEventId && `Game Id: ${row.fcEventId}`}>
            <span>{row.name}</span>
          </Tooltip>
        ),
      },
      {
        field: 'start',
        flex: 0.35,
        renderCell: ({ row }: GridRenderCellParams<Event>) => {
          return formatDate(row.start);
        },
      },
      {
        field: 'stop',
        headerName: 'Stop',
        flex: 0.35,
        renderCell: ({ row }: GridRenderCellParams<Event>) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!row.actualStop && (
                <Tooltip arrow title="No reported end time, using predicted end time">
                  <Info sx={{ color: 'secondary.main', marginRight: '5px' }} />
                </Tooltip>
              )}
              <span>{formatDate(row.actualStop ?? row.stop)}</span>
            </div>
          );
        },
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: ({ row }) => {
          return [
            <ActionButton
              key={`edit-${row.id}`}
              title={row.canUpdate.value ? 'Update' : row.canUpdate.reasons?.fullMessages[0] ?? ''}
              disabled={!row.canUpdate.value}
              Icon={Edit}
              onClick={() => editEvent(row)}
            />,
            <RemoveButton key={0} eventType={eventType} event={row} />,
          ];
        },
      },
    ],
    [editEvent, eventType],
  );

export interface EventsTableProps extends Omit<DataGridProps<Event>, 'columns'> {
  eventType: EventType;
  loading: boolean;
}

export const EventsTable = ({ eventType, loading, ...props }: EventsTableProps) => {
  const [currentEvent, setCurrentEvent] = useState<Event>();
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const editEvent = (event: Event) => {
    setCurrentEvent(event);
    setEditDialogOpen(true);
  };

  const columns = useColumns(editEvent, eventType);

  return (
    <>
      <SeparatedDataGrid
        columns={columns}
        loading={loading}
        slots={{
          baseCheckbox: ListCheckbox,
          columnResizeIcon: () => null,
          noRowsOverlay: NoRowsOverlay,
        }}
        {...props}
      />
      {currentEvent && (
        <EditEventDialog
          close={() => setEditDialogOpen(false)}
          open={editDialogOpen}
          event={currentEvent}
        />
      )}
    </>
  );
};

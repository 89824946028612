import {
  DeviceHub,
  KeyboardArrowDown,
  Layers,
  LiveTv,
  OfflineBolt,
  Schedule,
  Sell,
  Send,
  Settings,
  VolumeUp,
} from '@mui/icons-material';
import { ListItemIcon, ListItemText, Menu } from '@mui/material';
import { useRef, useState } from 'react';
import { BulkActionButton, BulkActionMenuItem } from '~/components/bulk-actions';
import { useAppContext } from '~/contexts';

export type BulkActionsProps = {
  onAddToGroup: () => void;
  onPlayOverlay: () => void;
  onScheduleShow: () => void;
  onSendCommand: () => void;
  onTagContent: () => void;
  onTuneChannel: () => void;
  onUpdateSettings: () => void;
  onUpdateSoftware: () => void;
  onVolume: () => void;
};

export const BulkActions = ({
  onAddToGroup,
  onPlayOverlay,
  onSendCommand,
  onScheduleShow,
  onTagContent,
  onTuneChannel,
  onUpdateSettings,
  onUpdateSoftware,
  onVolume,
}: BulkActionsProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { currentNetwork } = useAppContext();
  const [open, setOpen] = useState(false);

  return (
    <>
      <BulkActionButton
        ref={buttonRef}
        disableRipple
        id="bulk-actions-button"
        aria-controls={open ? 'bulk-actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={() => setOpen(true)}
      >
        Actions...
        <KeyboardArrowDown />
      </BulkActionButton>
      <Menu
        id="bulk-actions-menu"
        anchorEl={buttonRef.current}
        open={open}
        onClose={() => setOpen(false)}
        MenuListProps={{
          'aria-labelledby': 'bulk-actions-button',
        }}
      >
        <BulkActionMenuItem
          onClick={() => {
            onTagContent();
            setOpen(false);
          }}
        >
          <ListItemIcon>
            <Sell fontSize="small" />
          </ListItemIcon>
          Add Tag
        </BulkActionMenuItem>
        {currentNetwork.canManage.value && (
          <BulkActionMenuItem
            onClick={() => {
              onAddToGroup();
              setOpen(false);
            }}
          >
            <ListItemIcon>
              <DeviceHub fontSize="small" />
            </ListItemIcon>
            <ListItemText>Add To Device Group</ListItemText>
          </BulkActionMenuItem>
        )}
        <BulkActionMenuItem
          onClick={() => {
            onPlayOverlay();
            setOpen(false);
          }}
        >
          <ListItemIcon>
            <Layers fontSize="small" />
          </ListItemIcon>
          Play Overlay
        </BulkActionMenuItem>
        <BulkActionMenuItem
          onClick={() => {
            onScheduleShow();
            setOpen(false);
          }}
        >
          <ListItemIcon>
            <Schedule fontSize="small" />
          </ListItemIcon>
          <ListItemText>Schedule Show</ListItemText>
        </BulkActionMenuItem>
        <BulkActionMenuItem
          onClick={() => {
            onSendCommand();
            setOpen(false);
          }}
        >
          <ListItemIcon>
            <Send fontSize="small" />
          </ListItemIcon>
          <ListItemText>Send Command</ListItemText>
        </BulkActionMenuItem>
        <BulkActionMenuItem
          onClick={() => {
            onVolume();
            setOpen(false);
          }}
        >
          <ListItemIcon>
            <VolumeUp fontSize="small" />
          </ListItemIcon>
          Set Volume
        </BulkActionMenuItem>
        <BulkActionMenuItem
          onClick={() => {
            onTuneChannel();
            setOpen(false);
          }}
        >
          <ListItemIcon>
            <LiveTv fontSize="small" />
          </ListItemIcon>
          Tune Channel
        </BulkActionMenuItem>
        <BulkActionMenuItem
          onClick={() => {
            onUpdateSettings();
            setOpen(false);
          }}
        >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          <ListItemText>Update Settings</ListItemText>
        </BulkActionMenuItem>
        {currentNetwork.canManage.value && (
          <BulkActionMenuItem
            onClick={() => {
              onUpdateSoftware();
              setOpen(false);
            }}
          >
            <ListItemIcon>
              <OfflineBolt fontSize="small" />
            </ListItemIcon>
            Update Software
          </BulkActionMenuItem>
        )}
      </Menu>
    </>
  );
};

import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { DeleteFontDocument } from './delete.generated';

export const useDeleteFont = (options: ApiOptions<typeof DeleteFontDocument> = {}) =>
  useMutation(DeleteFontDocument, {
    ...useNotifications(DeleteFontDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Font deleted',
    }),
    ...options,
  });

import { useLazyQuery } from '@apollo/client';
import previewSrc from '@fanconnect/neo-client/dist/contentItem.html?url';
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useRenameContentItem } from '~/api/content-items';
import { AuditLogList } from '~/components/AuditLogs';
import {
  FullscreenPanelContainer,
  FullscreenPanelLeft,
  FullscreenPanelRight,
} from '~/components/FullscreenPanel';
import { Preview } from '~/components/preview';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { AppFormDetails, AppFormParamGroups, AppFormToolbar } from './components';
import { AppNewDocument, AppShowDocument } from './gql';
import {
  buildValidationSchema,
  initialValues,
  serializeValues,
  useAppPreview,
  type AppPayload,
  type EditorUserApp,
} from './lib';

export interface AppFormSubmitProps {
  patch: AppPayload;
  name?: string;
  templateVersionId?: number;
}

export interface AppFormProps {
  autoSaveName?: boolean;
  displayActions?: boolean;
  getApp?: boolean;
  id: number;
  onCapture?: () => Promise<void>;
  onSubmit: (props: AppFormSubmitProps) => Promise<void>;
}

export const AppForm = ({
  autoSaveName = true,
  displayActions = true,
  getApp = false,
  id,
  onCapture,
  onSubmit,
}: AppFormProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const link = useLink();

  const { currentNetwork } = useAppContext();

  const [getContentShow, editQuery] = useLazyQuery(AppShowDocument);
  const [getNewApp, newQuery] = useLazyQuery(AppNewDocument);

  const [renameApp] = useRenameContentItem();

  const contentItem: EditorUserApp | undefined =
    (getApp ? newQuery.data?.contentItem : editQuery.data?.contentItem) ?? undefined;

  const [appName, setAppName] = useState(contentItem?.name ?? '');
  useEffect(() => setAppName(contentItem?.name ?? ''), [contentItem?.name]);

  const validationSchema = useMemo(() => buildValidationSchema(contentItem), [contentItem]);

  const formik = useFormik({
    initialValues: initialValues(contentItem),
    onSubmit: async (values) => {
      const patch = serializeValues(validationSchema.cast(values));
      await onSubmit({ name: appName, patch, templateVersionId: contentItem?.templateVersion.id });
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema,
  });

  const { aspectRatio, payload } = useAppPreview(formik.values, contentItem?.templateVersion.id);

  useEffect(() => {
    if (getApp) return void getNewApp({ variables: { templateId: id } });

    void getContentShow({ variables: { contentItemId: id } });
  }, [currentNetwork.id, getApp, getContentShow, getNewApp, id]);

  const saveDisabled = useMemo(() => {
    if (!autoSaveName && appName !== contentItem?.name) return false;
    return formik.isSubmitting || !formik.isValid || !(getApp || formik.dirty);
  }, [appName, autoSaveName, contentItem, formik, getApp]);

  if (
    (getApp && newQuery.called && !newQuery.loading && newQuery.data?.contentItem == null) ||
    (editQuery.called && !editQuery.loading && editQuery.data?.contentItem == null)
  )
    return <Navigate replace to={link('/not-found')} />;

  const updateName = async (value: string) => {
    setAppName(value);
    if (getApp || !autoSaveName) return;
    return await renameApp({ variables: { contentItemId: id, name: value } });
  };

  const handleClose = () => {
    history.length > 1 ? navigate(-1) : navigate('..');
  };

  return (
    <>
      <AppFormToolbar
        appName={appName}
        displayActions={displayActions}
        existingContentItem={editQuery.data?.contentItem}
        id={id}
        onCapture={onCapture}
        onClose={handleClose}
        onSave={formik.handleSubmit}
        onUpdateName={updateName}
        saveDisabled={saveDisabled}
      />

      <FullscreenPanelContainer>
        <FullscreenPanelLeft
          tabs={[
            { label: 'Details', to: 'details' },
            { label: 'Settings', to: 'settings' },
            ...(currentNetwork.canManage.value
              ? [
                  {
                    label: 'Audit Logs',
                    to: 'audit-logs',
                  },
                ]
              : []),
          ]}
        >
          {location.pathname.endsWith('details') && (
            <AppFormDetails formik={formik} userApp={contentItem} />
          )}
          {(location.pathname.endsWith('settings') || location.pathname.endsWith('create')) && (
            <AppFormParamGroups
              formik={formik}
              groupedParams={contentItem?.templateVersion.groupedParams}
            />
          )}
          {location.pathname.endsWith('audit-logs') && (
            <AuditLogList recordTypes={['ContentItem']} contentItemId={contentItem?.id} />
          )}
        </FullscreenPanelLeft>
        <FullscreenPanelRight>
          <Preview aspectRatio={aspectRatio ?? undefined} payload={payload} src={previewSrc} />
        </FullscreenPanelRight>
      </FullscreenPanelContainer>
    </>
  );
};

import type { FetchResult } from '@apollo/client';
import { Cancel, Check } from '@mui/icons-material';
import Edit from '@mui/icons-material/Edit';
import {
  Box,
  ClickAwayListener,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import type { WithStyles } from '@mui/styles';
import { createStyles } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { useFormik } from 'formik';
import React, { useState } from 'react';

const styles = createStyles({
  root: {
    color: '#000',
    backgroundColor: 'transparent',
  },
  input: {},
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    marginRight: '8px',
  },
});

const useActionStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.dark,
    padding: theme.spacing(0.5),
    '& .MuiSvgIcon-root': {
      fontSize: '20px',
    },
  },
}));

export interface InlineEditTextFieldProps extends WithStyles<typeof styles> {
  inputId: string;
  value: string;
  update: (value: string) => Promise<FetchResult | undefined> | void;
  tooltip: string;
  defaultEdit?: boolean;
  validationSchema?: object /* A Yup schema or a function that returns a Yup schema */;
}

export const InlineEditTextField = withStyles(styles, { name: 'InlineEdit' })(({
  classes,
  inputId,
  value,
  update,
  tooltip,
  defaultEdit,
  validationSchema = {},
}: InlineEditTextFieldProps) => {
  const actionClasses = useActionStyles();

  const [editing, setEditing] = useState(defaultEdit ?? false);

  const formik = useFormik({
    initialValues: { name: value },
    onSubmit: async (values: { name: string }) => {
      const response = await update(values.name);
      if (response && response.errors) formik.resetForm({ values: { name: value } });
      setEditing(false);
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema,
  });

  // Called whenever editing is aborted, i.e. escape or click away
  const cancel = () => {
    setEditing(false);
    formik.resetForm({ values: { name: value } });
  };

  // Called on every key press to figure out if escape was pressed
  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') cancel();
  };

  const ReadView = () => {
    return (
      <Box className={classes.labelContainer}>
        <Typography className={classes.label} onClick={() => setEditing(true)}>
          {formik.values.name}
        </Typography>
        <Tooltip arrow title={tooltip}>
          <IconButton classes={actionClasses} onClick={() => setEditing(true)} size="large">
            <Edit />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  return (
    <div className={classes.root}>
      {!editing ? (
        <ReadView />
      ) : (
        <ClickAwayListener onClickAway={cancel}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              id={inputId}
              name="name"
              variant="standard"
              autoFocus
              fullWidth
              className={classes.input}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              onKeyDown={onKeyDown}
              InputProps={{
                classes: { root: classes.input },
                autoFocus: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color="secondary"
                      onClick={() => formik.handleSubmit()}
                      size="medium"
                      title="Accept"
                    >
                      <Check fontSize="inherit" />
                    </IconButton>

                    <IconButton
                      color="secondary"
                      onClick={() => cancel()}
                      size="medium"
                      title="Cancel"
                    >
                      <Cancel fontSize="inherit" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </ClickAwayListener>
      )}
    </div>
  );
});

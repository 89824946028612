import { Schedule } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useMemo, useState } from 'react';
import {
  useCreateScheduledShow,
  useDeleteScheduledShow,
  useUpdateScheduledShow,
} from '~/api/scheduled-show';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { ScheduledShowDialog } from '~/components/schedule/dialog';
import { PastSchedulesSwitch } from '~/components/schedule/past-switch';
import type { ScheduleInput } from '~/generated/graphql';
import { useConfirmDialog } from '~/hooks/dialogs';
import { assert } from '~/lib/assert';
import { isExpired } from '~/lib/schedules';
import type { DeviceSettings__ScheduledShow as ScheduledShow } from '../../queries/queries.generated';
import { useDevice } from '../context';
import { Table } from './schedule/components/table';

export const ScheduleList = () => {
  const { device } = useDevice();

  const [showPastEvents, setShowPastEvents] = useState(false);

  const [newScheduledShowOpen, setScheduledShowOpen] = useState(false);

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();
  const [confirmRemoveDevice, confirmRemoveDeviceProps] = useConfirmDialog();

  const [createSchedule] = useCreateScheduledShow();
  const [deleteSchedule] = useDeleteScheduledShow();
  const [updateSchedule] = useUpdateScheduledShow();

  const deleteScheduledShow = async (scheduledShow: ScheduledShow) => {
    if (!(await confirmDelete())) return;
    void deleteSchedule({ variables: { scheduledShowIds: [scheduledShow.id] } });
  };

  const removeDeviceFromScheduledShow = async (scheduledShow: ScheduledShow) => {
    if (!(await confirmRemoveDevice())) return;
    await updateSchedule({
      variables: {
        id: scheduledShow.id,
        patch: {
          deviceIds: scheduledShow.devices.filter((x) => x.id !== device.id).map((x) => x.id),
        },
      },
    });
  };

  const updateScheduledShow = async (
    name: string | null,
    schedule: ScheduleInput,
    showId: number,
    scheduledShowId?: number,
  ) => {
    assert(scheduledShowId != null, 'no scheduled show');
    await updateSchedule({
      variables: {
        id: scheduledShowId,
        patch: { name, showId, schedule },
      },
    });
  };

  const createScheduledShow = async (
    name: string | undefined,
    schedule: ScheduleInput,
    showId: number,
  ) => {
    await createSchedule({
      variables: {
        input: { name, schedule, selected: { ids: [device.id] }, showId },
      },
    });
  };

  const handleSwitchChange = (showPast: boolean) => {
    setShowPastEvents(showPast);
  };

  const scheduledShows = useMemo(() => {
    const allShows = device.scheduledShows;
    return showPastEvents
      ? allShows
      : allShows.filter((scheduledShow) => !isExpired(scheduledShow.schedule?.rrule));
  }, [device.scheduledShows, showPastEvents]);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <PastSchedulesSwitch onSwitchChange={handleSwitchChange} includeExpired={showPastEvents} />
        {device.canUpdate.value && (
          <Button
            onClick={() => setScheduledShowOpen(true)}
            startIcon={<Schedule />}
            variant="contained"
          >
            Schedule Show
          </Button>
        )}
      </Box>
      <Table
        onDelete={deleteScheduledShow}
        onRemoveDevice={removeDeviceFromScheduledShow}
        onSubmit={updateScheduledShow}
        schedules={scheduledShows}
      />
      <ScheduledShowDialog
        onClose={() => setScheduledShowOpen(false)}
        onSubmit={createScheduledShow}
        open={newScheduledShowOpen}
      />
      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt="Deleting this Schedule will remove it for this Device. Are you sure you want to delete this Schedule?"
        title="Delete Schedule"
      />
      <ConfirmDialog
        {...confirmRemoveDeviceProps}
        confirm="Remove"
        deleteConfirm
        prompt="Are you sure you want to remove this Device from this Scheduled Show?"
        title="Remove Device"
      />
    </>
  );
};

import { Box, Button, TextField, type DialogProps } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { object, string } from 'yup';
import { useUpdateFont } from '~/api/fonts';
import {
  Dialog,
  DialogContentTitle,
  DialogForm,
  DialogTitle,
} from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib';
import { toDataUri } from '~/lib/file';
import type { FontList__Font } from '../Fonts.generated';

const validationSchema = object({
  fileUri: string().label('Font'),
  name: string()
    .matches(/^[a-z ]+$/i, 'Letters and spaces only')
    .required()
    .trim()
    .label('Name'),
});

export interface UpdateFontDialogProps extends DialogProps {
  font: FontList__Font;
  onUpdate: () => void;
}

export const UpdateFontDialog = ({ font, onUpdate, ...props }: UpdateFontDialogProps) => {
  const [updateFont] = useUpdateFont();
  const [selectedFont, setSelectedFont] = useState<File>();

  const formik = useFormik({
    initialValues: { name: font.name },
    onSubmit: async (values) => {
      await updateFont({
        variables: {
          input: { ...validationSchema.cast(values), id: font.id },
        },
      });
      onUpdate();
    },
    validationSchema,
    validateOnChange: true,
  });

  const { getInputProps, open: selectFile } = useDropzone({
    accept: 'font/*',
    multiple: false,
    onDrop: async (accepted, _rejected, _event) => {
      if (accepted.length !== 1) return;
      setSelectedFont(accepted[0]);
      const fileUri = await toDataUri(accepted[0]);
      await formik.setFieldValue('fileUri', fileUri, true);
    },
  });

  return (
    <Dialog {...props}>
      <DialogTitle onClose={() => props.onClose?.({}, 'backdropClick')}>Update Font</DialogTitle>

      <DialogContent>
        <DialogContentTitle>Update the name and font file.</DialogContentTitle>

        <DialogForm id="update-font" onSubmit={formik.handleSubmit}>
          <label>
            <span className="label required">Name</span>
            <TextField
              autoFocus
              error={!!formik.errors.name}
              fullWidth
              helperText={formik.errors.name || ''}
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
            />
          </label>
          <Box sx={{ alignItems: 'center', display: 'flex', '& label': { flexBasis: '25%' } }}>
            <label>
              <span className="label">Font</span>
            </label>
            <Box>
              <input {...getInputProps()} />
              <Button onClick={selectFile} variant="outlined">
                Update Font
              </Button>
              {selectedFont && <Box>{selectedFont.name}</Box>}
            </Box>
          </Box>
        </DialogForm>
      </DialogContent>

      <DialogActions>
        <Button
          disabled={formik.isSubmitting}
          onClick={() => props.onClose?.({}, 'backdropClick')}
          variant="outlined"
        >
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={!formik.dirty || formik.isSubmitting || !formik.isValid}
          form="update-font"
          type="submit"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

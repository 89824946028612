import {
  Box,
  FormControl,
  MenuItem,
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
  Select,
  Switch,
  Typography,
  styled,
} from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { CountField } from './count-field';
import { daysOfWeek, type Day } from './lib/helpers';
import { type useScheduleOptions } from './lib/schedule-options';

type RepeatType = 'Daily' | 'Weekly';
type EndType = 'Never' | 'After' | 'On date';

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme, disabled }) => ({
  '&.MuiToggleButtonGroup-root': {
    display: 'flex',
    gap: theme.spacing(1),
  },
  '& > .MuiToggleButton-root.Mui-disabled': {
    backgroundColor: disabled ? '#e0e0e0' : theme.palette.primary.main,
    color: disabled ? '#939393' : theme.palette.common.white,
  },
}));

const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  borderRadius: '20px !important', // love this
  width: '40px',
  height: '40px',
  borderLeft: '1px solid #eee !important',
  padding: theme.spacing(1),
}));
export interface ScheduleFieldValues {
  scheduleOptions: ReturnType<typeof useScheduleOptions>;
}

export const ScheduleFields = ({ scheduleOptions }: ScheduleFieldValues) => {
  const {
    dtstart,
    setRepeatType,
    repeatType,
    setWeekDays,
    weekDays,
    allDay,
    start,
    end,
    setAllDay,
    setEndType,
    endType,
    setCount,
    count,
    until,
  } = scheduleOptions;

  return (
    <>
      <label>
        <span className="label required">Date & Times</span>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1,
            width: '100%',
          }}
        >
          <FormControl sx={{ mt: 1, width: '50%' }} variant="outlined">
            <DatePicker
              {...dtstart.props}
              slotProps={{
                textField: {
                  inputProps: { readOnly: true },
                },
              }}
            />
          </FormControl>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1,
              width: '50%',
              opacity: allDay ? 0 : 1,
              mt: 1,
            }}
          >
            <FormControl sx={{ width: '50%' }} variant="outlined">
              <TimePicker {...start.props} disabled={allDay} />
            </FormControl>
            <Typography>to</Typography>
            <FormControl sx={{ width: '50%' }} variant="outlined">
              <TimePicker {...end.props} disabled={allDay} />
            </FormControl>
          </Box>
        </Box>
      </label>

      <label>
        <span className="label">All Day</span>
        <FormControl>
          <Switch checked={allDay} color="primary" onChange={() => setAllDay(!allDay)} />
        </FormControl>
      </label>
      <label>
        <span className="label">Repeats</span>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1,
            width: '100%',
          }}
        >
          <FormControl sx={{ width: '25%' }} variant="outlined">
            <Select
              margin="none"
              onChange={(event) => setRepeatType(event.target.value as RepeatType)}
              value={repeatType}
              sx={{ marginRight: '8px' }}
            >
              <MenuItem value="Never">Never</MenuItem>
              <MenuItem value="Daily">Every Day</MenuItem>
              <MenuItem value="Weekly">Every Week</MenuItem>
            </Select>
          </FormControl>

          {repeatType === 'Weekly' && (
            <ToggleButtonGroup
              aria-label="days"
              onChange={(_event, newValue: readonly Day[]) => {
                if (newValue.length) setWeekDays(newValue);
              }}
              value={weekDays}
            >
              {daysOfWeek.map((day) => (
                <ToggleButton key={day} value={day} aria-label={day}>
                  {day}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
        </Box>
      </label>
      {repeatType !== 'Never' && (
        <label>
          <span className="label">Ends</span>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1,
              width: '100%',
            }}
          >
            <FormControl sx={{ width: '25%' }} variant="outlined">
              <Select
                margin="none"
                onChange={(event) => setEndType(event.target.value as EndType)}
                value={endType}
                sx={{ marginRight: '8px' }}
              >
                <MenuItem value="Never">Never</MenuItem>
                <MenuItem value="After">After</MenuItem>
                <MenuItem value="On date">On</MenuItem>
              </Select>
            </FormControl>

            {endType === 'Never' ? null : endType === 'After' ? (
              <CountField set={setCount} unit="occurrence" value={count} />
            ) : (
              <FormControl sx={{ width: '75%' }} variant="outlined">
                <DatePicker
                  {...until.props}
                  slotProps={{
                    textField: {
                      inputProps: { readOnly: true },
                    },
                  }}
                />
              </FormControl>
            )}
          </Box>
        </label>
      )}
    </>
  );
};

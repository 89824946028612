import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { UpdateDeviceDocument } from './update.generated';

export const useUpdateDevice = (options: ApiOptions<typeof UpdateDeviceDocument> = {}) =>
  useMutation(UpdateDeviceDocument, {
    ...useNotifications(UpdateDeviceDocument, {
      failure: (error) =>
        error.message
          ? error.message
          : 'An error occurred. Reload and try again or contact support.',
      success: 'Device updated.',
    }),
    ...options,
  });

import { CloudSync, CropOriginal, InterestsOutlined, Settings, Title } from '@mui/icons-material';
import type Konva from 'konva';
import type { Direction, Position, StudioItem, StudioFont } from '../context';

export const getScaledTransformerPosition = (
  node: Konva.Transformer,
  scale: Konva.Vector2d | undefined,
) => {
  const anchors = node.find('.top-left, .top-right, .bottom-left, .bottom-right');

  if (anchors.length !== 4) throw Error('cannot find transformer anchors');

  const { minX, minY, maxX, maxY } = anchors.reduce(
    (prev, node) => {
      const { x, y } = node.absolutePosition();
      return {
        minX: Math.min(prev.minX, x),
        minY: Math.min(prev.minY, y),
        maxX: Math.max(prev.maxX, x),
        maxY: Math.max(prev.maxY, y),
      };
    },
    {
      minX: Number.MAX_SAFE_INTEGER,
      minY: Number.MAX_SAFE_INTEGER,
      maxX: Number.MIN_SAFE_INTEGER,
      maxY: Number.MIN_SAFE_INTEGER,
    },
  );

  const width = maxX - minX;
  const height = maxY - minY;

  const scaleX = scale?.x ?? 1;
  const scaleY = scale?.y ?? 1;

  const topLeft = anchors.find((x) => x.hasName('top-left'));

  return {
    box: {
      x: minX / scaleX,
      y: minY / scaleY,
      width: width / scaleX,
      height: height / scaleY,
    },
    position: {
      x: topLeft?.absolutePosition().x ?? 0 / scaleX,
      y: topLeft?.absolutePosition().y ?? 0 / scaleY,
    },
  };
};

const maxVideoMessage = 'You can only add 2 videos to a design.';

export const links = [
  { name: 'Text' as const, Icon: Title },
  { name: 'Media' as const, Icon: CropOriginal },
  { name: 'Shapes' as const, Icon: InterestsOutlined },
  { name: 'Settings' as const, Icon: Settings },
];

export type LinkName = (typeof links)[number]['name'];

export const settingsTabs = [
  { name: 'Settings' as const, Icon: Settings },
  { name: 'Data Source' as const, Icon: CloudSync },
];

export type SettingsTabName = (typeof settingsTabs)[number]['name'];

export type StudioTransformerState = {
  box?: {
    height: number;
    width: number;
    x: number;
    y: number;
  };
  dragging: boolean;
  node?: Konva.Transformer;
  transforming: boolean;
};

export type StudioState = {
  activeSettingsTab: SettingsTabName;
  activeSidebarItem: LinkName;
  copiedItems: StudioItem[];
  dataItemCellSelectOpen: boolean;
  dirty: boolean;
  editingId?: string;
  fonts?: StudioFont[];
  history: StudioItem[][];
  historyStep: number;
  items: StudioItem[];
  loaded: boolean;
  message?: string;
  selectedIds: string[];
  stage?: Konva.Stage;
  transformer: StudioTransformerState;
};

export type StudioAction =
  | { type: 'ADD_ITEMS'; items: StudioItem[] }
  | { type: 'ALIGN_ITEM'; id: string; position: Position }
  | { type: 'COPY_ITEMS'; ids: string[] }
  | { type: 'DELETE_ITEMS'; ids: string[] }
  | { type: 'EDIT_ITEM'; id: string | undefined }
  | { type: 'LOAD_ITEMS'; items: StudioItem[] }
  | { type: 'MOVE_ITEM'; id: string; direction: Direction }
  | { type: 'REDO' }
  | { type: 'RESET_DIRTY' }
  | { type: 'SELECT_ITEMS'; ids: string[] }
  | { type: 'SET_DATA_ITEM_CELL_SELECT'; open: boolean }
  | { type: 'SET_FONTS'; fonts: StudioFont[] }
  | { type: 'SET_MESSAGE'; message: string | undefined }
  | { type: 'SET_SETTINGS_TAB'; tab: SettingsTabName }
  | { type: 'SET_SIDEBAR_ITEM'; name: LinkName }
  | { type: 'SET_STAGE'; stage: Konva.Stage }
  | { type: 'SET_TRANSFORMER'; transformer: Partial<StudioTransformerState> }
  | { type: 'UNDO' }
  | {
      type: 'UPDATE_ITEMS';
      items: Array<Partial<Omit<StudioItem, 'type'>> & Required<Pick<StudioItem, 'id'>>>;
    };

export const reducer = (state: StudioState, action: StudioAction) => {
  switch (action.type) {
    case 'ADD_ITEMS': {
      const { items } = action;

      // If item is not provided or is an empty array, clear the selection
      if (items.length === 0) return { ...state };

      const videoCount = state.items.filter((x) => x.type === 'video').length;
      const newVideoCount = items.filter((x) => x.type === 'video').length;

      if (videoCount + newVideoCount > 2) {
        return { ...state, message: maxVideoMessage };
      }

      const newItems = [...state.items, ...items];

      return {
        ...state,
        ...(items.length === 1 && { activeSidebarItem: 'Settings' as const }),
        dirty: true,
        editingId: undefined,
        history: [...state.history.slice(0, state.historyStep + 1), newItems],
        historyStep: state.historyStep + 1,
        items: newItems,
        selectedIds: items.map((x) => x.id),
      };
    }
    case 'ALIGN_ITEM': {
      const { history, historyStep, items, stage } = state;
      const { id, position } = action;
      if (!stage) return { ...state };

      const node = stage.findOne(`#${id}`);
      if (!node) return { ...state };

      const stageWidth = stage.width() / stage.scaleX();
      const stageHeight = stage.height() / stage.scaleY();

      const boundingBox = node.getClientRect({ relativeTo: stage });

      const offsetX = node.x() - boundingBox.x;
      const offsetY = node.y() - boundingBox.y;

      const options = {
        ...(position === 'left' && { x: offsetX }),
        ...(position === 'center' && { x: stageWidth / 2 - boundingBox.width / 2 + offsetX }),
        ...(position === 'right' && { x: stageWidth - boundingBox.width + offsetX }),
        ...(position === 'top' && { y: offsetY }),
        ...(position === 'middle' && { y: stageHeight / 2 - boundingBox.height / 2 + offsetY }),
        ...(position === 'bottom' && { y: stageHeight - boundingBox.height + offsetY }),
      };

      const newItems = items.map((x) => (x.id === id ? { ...x, ...options } : x));

      return {
        ...state,
        dirty: true,
        history: [...history.slice(0, historyStep + 1), newItems],
        historyStep: historyStep + 1,
        items: newItems,
      };
    }
    case 'COPY_ITEMS': {
      return {
        ...state,
        copiedItems: state.items.filter((x) => action.ids.includes(x.id)),
      };
    }

    case 'DELETE_ITEMS': {
      const newItems = state.items.filter((x) => !action.ids.includes(x.id));
      return {
        ...state,
        activeSidebarItem: 'Text' as const,
        dirty: true,
        history: [...state.history.slice(0, state.historyStep + 1), newItems],
        historyStep: state.historyStep + 1,
        items: newItems,
        selectedIds: [],
        transformer: {
          ...state.transformer,
          box: undefined,
        },
      };
    }
    case 'EDIT_ITEM': {
      return {
        ...state,
        editingId: action.id,
      };
    }
    case 'LOAD_ITEMS': {
      return {
        ...state,
        dirty: false,
        history: [action.items],
        historyStep: 0,
        items: action.items,
        loaded: true,
      };
    }
    case 'MOVE_ITEM': {
      const { history, historyStep, items } = state;
      const { direction, id } = action;
      const currentIndex = items.findIndex((x) => x.id === id);

      if (currentIndex < 0) return { ...state };

      if (currentIndex === items.length - 1 && (direction === 'forward' || direction === 'front')) {
        return { ...state };
      }

      if (currentIndex === 0 && (direction === 'backward' || direction === 'back')) {
        return { ...state };
      }

      const newItems =
        direction === 'back'
          ? [items[currentIndex], ...items.filter((x) => x.id !== id)]
          : direction === 'front'
          ? [...items.filter((x) => x.id !== id), items[currentIndex]]
          : direction === 'forward'
          ? [
              ...items.slice(0, currentIndex + 2).filter((x) => x.id !== id),
              items[currentIndex],
              ...items.slice(currentIndex + 2),
            ]
          : [
              ...items.slice(0, currentIndex - 1),
              items[currentIndex],
              ...items.slice(currentIndex - 1).filter((x) => x.id !== id),
            ];

      return {
        ...state,
        dirty: true,
        history: [...history.slice(0, historyStep + 1), newItems],
        historyStep: historyStep + 1,
        items: newItems,
      };
    }
    case 'REDO': {
      const { history, historyStep } = state;
      if (historyStep === history.length - 1) return { ...state };

      return {
        ...state,
        dirty: true,
        historyStep: historyStep + 1,
        items: history[historyStep + 1],
      };
    }
    case 'RESET_DIRTY': {
      return {
        ...state,
        dirty: false,
      };
    }
    case 'SELECT_ITEMS': {
      if (action.ids.length === 0)
        return {
          ...state,
          activeSidebarItem: 'Text' as const,
          selectedIds: [],
          transformer: {
            ...state.transformer,
            box: undefined,
          },
        };

      return {
        ...state,
        ...(action.ids.length === 1 && {
          activeSettingsTab: 'Settings' as const,
          activeSidebarItem: 'Settings' as const,
        }),
        selectedIds: action.ids,
      };
    }
    case 'SET_DATA_ITEM_CELL_SELECT': {
      return { ...state, dataItemCellSelectOpen: action.open };
    }
    case 'SET_FONTS': {
      return { ...state, fonts: action.fonts };
    }
    case 'SET_MESSAGE': {
      return { ...state, message: action.message };
    }
    case 'SET_SETTINGS_TAB': {
      return { ...state, activeSettingsTab: action.tab };
    }
    case 'SET_SIDEBAR_ITEM': {
      return {
        ...state,
        activeSidebarItem: action.name,
      };
    }
    case 'SET_STAGE': {
      return {
        ...state,
        stage: action.stage,
      };
    }
    case 'SET_TRANSFORMER': {
      return {
        ...state,
        transformer: {
          ...state.transformer,
          ...(action.transformer.box && { box: action.transformer.box }),
          ...(action.transformer.dragging !== undefined && {
            dragging: action.transformer.dragging,
          }),
          ...(action.transformer.node && { node: action.transformer.node }),
          ...(action.transformer.transforming !== undefined && {
            transforming: action.transformer.transforming,
          }),
        },
      };
    }
    case 'UNDO': {
      const { history, historyStep } = state;
      if (historyStep === 0) return { ...state };

      return {
        ...state,
        dirty: true,
        historyStep: historyStep - 1,
        items: history[historyStep - 1],
      };
    }
    case 'UPDATE_ITEMS': {
      const { items } = action;

      const newItems = state.items.map((x) => {
        const updatedItem = items.find((item) => item.id === x.id);
        return updatedItem ? { ...x, ...updatedItem } : x;
      });

      return {
        ...state,
        dirty: true,
        history: [...state.history.slice(0, state.historyStep + 1), newItems],
        historyStep: state.historyStep + 1,
        items: newItems,
      };
    }
  }
};

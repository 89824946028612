import { useQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { useAppContext } from '~/contexts';
import { useStudio, type StudioFont } from '../../../context';
import { StudioFontsDocument } from './StudioFonts.generated';
import { FONTS } from './fonts';

export const useFonts = () => {
  const { currentNetwork } = useAppContext();
  const { fonts, setFonts } = useStudio();

  const { data, loading } = useQuery(StudioFontsDocument, {
    variables: { networkId: currentNetwork.id },
  });

  const uploadedFonts: StudioFont[] = useMemo(
    () =>
      data?.network?.fonts.map((x) => ({
        __fontId: x.id,
        family: [{ uri: x.file.uri }],
        key: x.name,
        value: x.name,
      })) ?? [],
    [data?.network?.fonts],
  );

  const combinedFonts = useMemo(() => [...FONTS, ...uploadedFonts], [uploadedFonts]);

  const fontFaces = useMemo(
    () =>
      combinedFonts.reduce<FontFace[]>((faces, font) => {
        if (!font.family) return faces;
        return [
          ...faces,
          ...font.family.map(
            (family) => new FontFace(font.key, `url(${family.uri})`, family.options),
          ),
        ];
      }, []),
    [combinedFonts],
  );
  fontFaces.forEach((x) => document.fonts.add(x));

  useEffect(() => {
    if (loading || fonts) return;
    void Promise.all(fontFaces.map((x) => x.load())).then(() => {
      setFonts(combinedFonts);
    });
  }, [combinedFonts, fontFaces, fonts, loading, setFonts]);
};

import { Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import { useMemo } from 'react';
import {
  Dialog,
  DialogContentTitle,
  DialogForm,
  DialogTitle,
} from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import { capitalize, pluralize } from '~/lib/string';
import type {
  DeviceList__Channel as Channel,
  DeviceList__DeviceGroup as DeviceGroup,
  DeviceList__TvBrand as TVBrand,
} from '../../queries/list.generated';
import { SCREENSHOT_INTERVALS } from '../lib/helpers';
import type { DeviceBulkPatchInput } from './BulkSettingsDialog';

interface BulkUpdateConfirmDialogProps {
  channels: ReadonlyArray<Channel>;
  deviceGroups: ReadonlyArray<DeviceGroup>;
  loading: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  open: boolean;
  patch: DeviceBulkPatchInput;
  selectedDeviceCount: number;
  tvBrands: ReadonlyArray<TVBrand>;
}

interface PatchEntryProps {
  label: string;
  value: string;
}

const PatchEntry = ({ label, value }: PatchEntryProps) => (
  <Box mb={2}>
    <label>
      <span className="label" style={{ fontWeight: 'bold' }}>
        {label}:{' '}
      </span>
      <span>{value}</span>
    </label>
  </Box>
);

export const BulkUpdateConfirmDialog = ({
  channels,
  deviceGroups,
  loading,
  onConfirm,
  onCancel,
  open,
  patch,
  selectedDeviceCount,
  tvBrands,
}: BulkUpdateConfirmDialogProps) => {
  const channel = useMemo(
    () => channels.find((c) => c.id === patch.defaultChannelId),
    [channels, patch],
  );

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="sm" fullWidth>
      <DialogTitle onClose={onCancel}>Bulk Update</DialogTitle>
      <DialogContent>
        <DialogContentTitle>
          Are you sure you want to update{' '}
          {selectedDeviceCount > 0 ? selectedDeviceCount : 'all selected'}{' '}
          {selectedDeviceCount > 0 ? pluralize('Device', selectedDeviceCount) : 'Devices'} with the
          following changes:
        </DialogContentTitle>
        <DialogForm>
          {patch.screenshotInterval !== undefined && (
            <PatchEntry
              label="Automatic Screenshots"
              value={
                patch.screenshotInterval === null
                  ? 'Disable'
                  : SCREENSHOT_INTERVALS[patch.screenshotInterval] || 'Unknown'
              }
            />
          )}

          {patch.defaultChannelId !== undefined && (
            <PatchEntry
              label="Default Channel"
              value={
                channel
                  ? `${channel.name}${channel.number ? ` (${channel.number})` : ''}`
                  : 'No Default Channel'
              }
            />
          )}

          {patch.demo !== undefined && (
            <PatchEntry label="Demo" value={patch.demo ? 'Yes' : 'No'} />
          )}

          {patch.deviceGroupId !== undefined && (
            <PatchEntry
              label="Device Group"
              value={
                deviceGroups.find((g) => g.id === patch.deviceGroupId)?.name ?? 'No Device Group'
              }
            />
          )}

          {patch.eventStream !== undefined && (
            <PatchEntry label="Event Stream" value={patch.eventStream ? 'Yes' : 'No'} />
          )}

          {patch.internal !== undefined && (
            <PatchEntry label="Internal" value={patch.internal ? 'Yes' : 'No'} />
          )}

          {patch.nightlyBehavior !== undefined && (
            <PatchEntry label="Nightly Reboot/Restart" value={capitalize(patch.nightlyBehavior)} />
          )}

          {patch.overscan !== undefined && (
            <PatchEntry label="Overscan" value={`${patch.overscan}%`} />
          )}

          {patch.realtime !== undefined && (
            <PatchEntry label="Realtime" value={patch.realtime ? 'Yes' : 'No'} />
          )}

          {patch.silence !== undefined && (
            <PatchEntry label="Silence" value={patch.silence ? 'Yes' : 'No'} />
          )}

          {patch.tvBrandId !== undefined && (
            <PatchEntry
              label="TV Brand"
              value={tvBrands.find((b) => b.id === patch.tvBrandId)?.name ?? 'No TV Brand'}
            />
          )}
        </DialogForm>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton
          loadingPosition="start"
          variant="contained"
          color="primary"
          loading={loading}
          startIcon={<Save />}
          onClick={onConfirm}
        >
          Apply Changes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

import { Apps, Edit, Info } from '@mui/icons-material';
import { Box, TextField, Tooltip } from '@mui/material';
import type { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type KeyboardEvent,
  type MouseEvent,
} from 'react';
import { useUpdatePlaylistItem } from '~/api/playlist-items';
import { toSeconds } from '~/lib/datetime';
import { formatRuntime } from '~/lib/runtime';
import type { PlaylistDesign__PlaylistItem as PlaylistItem } from './index.generated';
import { DurationContainer, ThumbnailContainer } from './styles';

export const DurationCell = ({ row }: GridRenderCellParams<PlaylistItem>) => {
  const [update] = useUpdatePlaylistItem();
  const [edit, setEdit] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      if (row.durationReadonly) return;
      setEdit(() => true);
    },
    [row],
  );

  useEffect(() => setEdit(false), [row.duration]);

  const handleKeypress = useCallback(
    async (event: KeyboardEvent) => {
      if (event.key === 'Escape') setEdit(() => false);
      if (event.key === 'Enter' && event.target instanceof HTMLInputElement) {
        const value = event.target.value.trim();
        const seconds = parseInt(value);
        const oldSeconds = toSeconds(row.duration ?? {});
        if (seconds >= 5 && seconds <= 3600 && seconds !== oldSeconds) {
          setDisabled(true);
          await update({ variables: { playlistItemId: row.id, patch: { duration: { seconds } } } });
          setDisabled(false);
        } else {
          setEdit(() => false);
        }
      }
    },
    [row, update],
  );

  const duration = useMemo(() => formatRuntime(row.duration), [row]);

  if (!duration) return null;

  if (edit) {
    return (
      <TextField
        autoFocus
        disabled={disabled}
        defaultValue={row.duration ? toSeconds(row.duration) : ''}
        onBlur={() => setEdit(() => false)}
        onClick={(event) => event.stopPropagation()}
        onKeyDown={handleKeypress}
        sx={{ width: '100px' }}
        type="number"
      />
    );
  }

  return (
    <Tooltip
      title={
        row.durationReadonly
          ? 'Duration cannot be edited'
          : 'Enter a duration between 5 and 3600 seconds'
      }
    >
      <DurationContainer className={row.durationReadonly ? 'readonly' : ''} onClick={handleClick}>
        {duration}
        {!row.durationReadonly && <Edit color="secondary" sx={{ fontSize: '14px' }} />}
      </DurationContainer>
    </Tooltip>
  );
};

const toDateTime = (sqlDate: string | null | undefined): DateTime | null =>
  sqlDate ? DateTime.fromSQL(sqlDate) : null;

export const ExpiresHeader = () => (
  <>
    Expires{' '}
    <Tooltip
      arrow
      title={
        'Adding an expiration date will cause this item to be removed from the playlist at expiration. \
         It will be removed at midinight at the end of the selected date. \
         The expiration date must be on or after today.'
      }
    >
      <Info fontSize="small" sx={{ color: 'info.main', ml: 1 }} />
    </Tooltip>
  </>
);

export const ExpiresCell = ({ row }: GridRenderCellParams<PlaylistItem>) => {
  const [value, setValue] = useState(toDateTime(row.expires));

  useEffect(() => setValue(toDateTime(row.expires)), [row.expires]);

  const [updatePlaylistItem] = useUpdatePlaylistItem();

  const update = useCallback(
    (date: DateTime | null) =>
      updatePlaylistItem({
        variables: { playlistItemId: row.id, patch: { expires: date?.toSQLDate() ?? null } },
      }),
    [row.id, updatePlaylistItem],
  );

  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: 'background.default',
        borderRadius: '4px',
        color: 'text.primary',
        cursor: 'pointer',
        display: 'flex',
        gap: 1,
      }}
    >
      <DatePicker
        disablePast
        format="ccc, DD"
        onAccept={update}
        onChange={setValue}
        slotProps={{
          field: {
            clearable: true,
            onClear: () => update(null),
          },
          inputAdornment: {
            position: 'end',
            sx: {
              svg: {
                color: 'primary.dark',
                fontSize: '20px',
              },
              marginRight: 1,
            },
          },
          textField: {
            inputProps: {
              disabled: true,
            },
            InputProps: {
              disableUnderline: true,
              sx: {
                fontSize: '14px',
                padding: '1px 8px',
              },
            },
            placeholder: 'N/A',
            variant: 'standard',
          },
        }}
        value={value}
      />
    </Box>
  );
};

export const ThumbnailCell = ({ row }: GridRenderCellParams<PlaylistItem>) => (
  <ThumbnailContainer>
    {row.contentItem.thumbnailUri ? (
      <img crossOrigin="anonymous" src={row.contentItem.thumbnailUri} style={{ height: '100%' }} />
    ) : (
      <Apps color="secondary" />
    )}
  </ThumbnailContainer>
);
